import React, { FC } from 'react';
import { renderItems } from '../../utils';
import { IFeaturedHub } from './FeaturedHub.def';
import HubHeader from './HubHeader';

const FeaturedHub: FC<IFeaturedHub> = ({
  title,
  subtitle,
  moreLink = null,
  variant = 'blue',
  links,
  articles,
  linkClick = () => {},
}) => (
  <section className={`hub bg-deepblue`}>
    <div className="container">
      <div className="fullFeaturedWrapper">
        <HubHeader
          title={title}
          subtitle={subtitle}
          moreLink={moreLink}
          links={links}
          linkClick={linkClick}
        />
      </div>
      {articles && (
        <div
          className={`
          portfolioView
          ${variant === 'white' ? 'portfolioViewWhite_variant' : ''}`}
        >
          {renderItems(articles, 'ArticleOverlay', linkClick)}
        </div>
      )}
    </div>
  </section>
);

export default FeaturedHub;
