import React from 'react';
import { graphql, PageProps } from 'gatsby';

import PageHeader from '../../components/PageHeader';
import FeaturedGuide from '../../components/FeaturedGuide';
import FeaturedHub from '../../components/FeaturedHub';
import GuideBlocks from '../../components/GuideBlocks';
import SquareBrowser from '../../components/SquareBrowser';
import HorizontalBrowser from '../../components/HorizontalBrowser/HorizontalBrowser';
import Seo from '../../components/Seo/Seo';
import {
  getArticleExcerptFromWpPost,
  getFeaturedImageDataFromWpPost,
  getImageFromWpImage,
  getPlaceholderGatsbyImg,
  getPlaceholderWpGatsbyImg,
} from '../../utils';
import { IWordPressImage } from '../../models/IWordPressImage';
import { TFeaturedHubVariant } from '../../components/FeaturedHub/FeaturedHub.def';
import { IReviewTile } from '../../components/ReviewTile/ReviewTile.def';
import { HT_DEFAULT_PAGE_NAME, HT_PAGE_CATEGORY_ARCHIVE_PAGE } from '../../constants';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface TravelChildCategoryTemplateProps {
  category: Queries.WpCategory;
  posts: Queries.WpPostConnection;
}

const TravelChildCategoryTemplate = ({
  data,
  location,
}: PageProps<TravelChildCategoryTemplateProps>) => {
  const {
    category: { name, uri, templateForCategories: fieldsForCategory },
    posts,
  } = data;
  const breadCrumbs = [
    {
      link: '/',
      text: 'Home',
    },
    {
      link: '/travel/',
      text: 'travel',
    },
  ];
  //Set up fields for the Guide Header
  const guideHeaderTitle = fieldsForCategory?.upgpTemplateForCategoriesGuideHeader || '';

  //Set up the props for the Square Browser
  let sbTitle = '';
  if (name) {
    sbTitle = name.endsWith('s') ? name.slice(undefined, -1) : name;
    sbTitle += ' Guides';
  }
  let sbMoreLinkData = {
    text: 'SEE MORE',
    link: uri + 'page/2/',
  };
  const latestPosts = posts?.nodes;
  const categoryObj = { text: sbTitle, link: sbMoreLinkData.link };
  const postIds = fieldsForCategory?.upgpTemplateForCategoriesPostsForGuideBrowser?.map(
    (p) => p?.upgpTemplateForCategoriesPostForGuideBrowser?.id,
  );
  const sbArticles =
    [...latestPosts]
      .filter((p) => postIds?.includes(p.id))
      .map((wpArticle) => {
        const featuredImageNode = wpArticle?.featuredImage?.node;
        const featuredImageNodeThumb: IWordPressImage = featuredImageNode
          ? {
              sourceUrl: featuredImageNode.sourceUrl || getPlaceholderGatsbyImg(),
              altText: featuredImageNode.altText || '',
            }
          : getPlaceholderWpGatsbyImg();
        return {
          thumbnail: getImageFromWpImage(featuredImageNodeThumb),
          imageOverlay: true,
          title: wpArticle?.title || 'No Title',
          link: wpArticle?.uri || '#',
          category: categoryObj,
        };
      }) || [];

  //Set up the props for the FeaturedGuide
  const featuredPost = fieldsForCategory?.upgpTemplateForCategoriesFeaturedPost;
  let fgFeaturedImage;
  let fgMoreLink = { link: '', text: '' };
  let fgTitle = '';
  if (featuredPost) {
    fgFeaturedImage = {
      alt: featuredPost.featuredImage?.node?.altText || '',
      src: featuredPost.featuredImage?.node?.sourceUrl || getPlaceholderGatsbyImg(),
    };
    fgMoreLink.link = featuredPost.uri || '';
    fgMoreLink.text = 'LEARN MORE';
    fgTitle = featuredPost.title || '';
  } else {
    fgFeaturedImage = getImageFromWpImage(getPlaceholderWpGatsbyImg());
  }

  //Set up the props for the Featurd Hub components
  let featuredHubProps;
  const featuredHubs = fieldsForCategory?.upgpTemplateForCategoriesFeaturedHubs;
  if (featuredHubs && featuredHubs.length > 0) {
    const dummyMoreLink = { link: '# ' };
    featuredHubProps = featuredHubs.map((featuredHubData, index) => {
      const regex = new RegExp(
        '\\b' +
          featuredHubData?.upgpTemplateForCategoriesFeaturedHubKeyword?.toLocaleLowerCase() +
          '\\b',
      );
      const filteredArticles =
        latestPosts
          ?.filter((p) => p?.title?.toLowerCase().match(regex))
          .slice(0, 4)
          .map((wpPost) => {
            const featuredImageNode = wpPost?.featuredImage?.node;
            const featuredImageNodeThumb: IWordPressImage = featuredImageNode
              ? {
                  sourceUrl: featuredImageNode.sourceUrl || getPlaceholderGatsbyImg(),
                  altText: featuredImageNode.altText || '',
                }
              : getPlaceholderWpGatsbyImg();
            return {
              link: wpPost?.uri || '#',
              thumbnail: getImageFromWpImage(featuredImageNodeThumb),
              title: wpPost?.title || 'No Title',
              moreLink: { text: 'Learn More', link: wpPost?.uri || '#' },
            };
          }) || [];
      const variant: TFeaturedHubVariant = index % 2 == 0 ? 'blue' : 'white';
      return {
        moreLink: dummyMoreLink,
        title: featuredHubData?.upgpTemplateForCategoriesFeaturedHubTitle || '',
        subtitle: featuredHubData?.upgpTemplateForCategoriesFeaturedHubSubtitle || '',
        variant: variant,
        articles: filteredArticles,
      };
    });
  }

  //Set up the props for the GuideBlocks component (latest articles)
  const gbTitle = 'Latest ' + sbTitle;
  const gbArticles =
    [...latestPosts]
      .slice(0, 6)
      .map((wpArticle: Queries.WpPost) => getArticleExcerptFromWpPost(wpArticle)) || [];

  const isHotelLander = name === 'Hotels';
  //If this is the hotel lander, set up props for the Horizontal Browser for the Hotel Loyalty Program Reviews
  //TODO: Get these from the CMS
  const hbSeeAllLink = {
    link: '',
    text: '',
  };
  const hbSubtitle =
    'Browse our in-depth guides written by seasoned travelers that help to demystify the complexities of hotel loyalty programs.';
  const hbTitle = 'Hotel Loyalty Program Reviews';
  //TODO: Get these from the Loyalty Programs post format once the migration is done
  const hbCards: IReviewTile[] = [];
  [
    'cG9zdDoyNTk=',
    'cG9zdDoxOTk5NDM=',
    'cG9zdDoxMzU2NTM=',
    'cG9zdDo4MzQ2',
    'cG9zdDo3MDMz',
    'cG9zdDoxODY3NTE=',
    'cG9zdDo4Mjcx',
    'cG9zdDoxNDEzMTc=',
    'cG9zdDoxOTI4ODU=',
    'cG9zdDo5MA==',
    'cG9zdDozMzEyNA==',
    'cG9zdDozMzk3MzQ=',
  ].forEach((pId) => {
    const wpArticle = latestPosts.find((p) => pId === p?.id);
    const featuredImageNode = wpArticle?.featuredImage?.node;
    const featuredImageNodeThumb: IWordPressImage = featuredImageNode
      ? {
          sourceUrl: featuredImageNode.sourceUrl || getPlaceholderGatsbyImg(),
          altText: featuredImageNode.altText || '',
        }
      : getPlaceholderWpGatsbyImg();
    const articleLink = wpArticle?.uri || '#';
    hbCards.push({
      thumbnail: getImageFromWpImage(featuredImageNodeThumb),
      title: {
        link: articleLink,
        text: wpArticle?.title || 'No Title',
      },
      summary: `${wpArticle?.excerpt?.slice(0, 150)}...` || '',
    });
  });

  return (
    <HTPageWrapper
      category={'Travel, ' + name + ', ' + HT_PAGE_CATEGORY_ARCHIVE_PAGE}
      title={name || HT_DEFAULT_PAGE_NAME}
      location={location}
    >
      <div className={`${!isHotelLander ? 'airlines' : 'hotels'}`}>
        <PageHeader title={name || ''} breadCrumbs={breadCrumbs} subTitle={guideHeaderTitle} />
        <SquareBrowser title={sbTitle} moreLink={sbMoreLinkData} articles={sbArticles} />
        {featuredPost && (
          <FeaturedGuide
            featuredImage={fgFeaturedImage}
            moreLink={fgMoreLink}
            title={fgTitle}
            hasTransparentBackground={true}
          />
        )}
        {isHotelLander && (
          <HorizontalBrowser
            seeAllLink={hbSeeAllLink}
            subtitle={hbSubtitle}
            title={hbTitle}
            cards={hbCards}
            notScrollable={false}
            componentName="ReviewTile"
          />
        )}
        {featuredHubProps &&
          featuredHubProps.map((featuredHubData) => (
            <FeaturedHub key={featuredHubData.title} {...featuredHubData} />
          ))}
        <GuideBlocks
          articles={gbArticles}
          moreLink={sbMoreLinkData}
          title={gbTitle}
          variant="columns"
        />
      </div>
    </HTPageWrapper>
  );
};

export default TravelChildCategoryTemplate;

export const Head = ({ data }: PageProps<TravelChildCategoryTemplateProps>) => {
  const { seo, templateForCategories } = data.category;
  const featuredImageData =
    templateForCategories && templateForCategories.upgpTemplateForCategoriesFeaturedPost
      ? getFeaturedImageDataFromWpPost(templateForCategories.upgpTemplateForCategoriesFeaturedPost)
      : null;

  const categoryName = data?.category?.name ?? '';
  const categoryUri = data?.category?.uri ?? '';

  // We are adding the main Travel to those sub-categories.
  const travelCategories = ['Car Rentals', 'Hiking & Camping'];
  const travelUris = ['/travel/car-rentals/', '/travel/hiking-and-camping/'];

  const catName = travelCategories.includes(categoryName) ? 'Travel' : categoryName;
  const catUri = travelUris.includes(categoryUri) ? '/travel/' : categoryUri;

  return (
    <Seo
      wpSeo={seo}
      featuredImageData={featuredImageData}
      isArchivePage={true}
      archiveName={catName}
      uri={catUri}
    />
  );
};

export const query = graphql`
  query TravelChildCategoryWPosts($id: String, $name: String) {
    category: wpCategory(id: { eq: $id }) {
      name
      uri
      templateForCategories {
        upgpTemplateForCategoriesGuideHeader
        upgpTemplateForCategoriesFeaturedPost {
          ... on WpPost {
            featuredImage {
              node {
                caption
                width
                height
                sourceUrl
                altText
              }
            }
            title
            uri
          }
        }
        upgpTemplateForCategoriesFeaturedHubs {
          upgpTemplateForCategoriesFeaturedHubKeyword
          upgpTemplateForCategoriesFeaturedHubSubtitle
          upgpTemplateForCategoriesFeaturedHubTitle
        }
        upgpTemplateForCategoriesPostsForGuideBrowser {
          upgpTemplateForCategoriesPostForGuideBrowser {
            ... on WpPost {
              id
              title
            }
          }
        }
      }
      seo {
        title
        metaRobotsNofollow
        metaRobotsNoindex
        canonical
        metaDesc
        opengraphDescription
        opengraphImage {
          height
          sourceUrl
          width
          mediaType
        }
        opengraphType
        opengraphTitle
        opengraphUrl
        opengraphSiteName
        opengraphPublisher
        opengraphModifiedTime
        schema {
          raw
        }
      }
    }
    posts: allWpPost(
      filter: { categories: { nodes: { elemMatch: { name: { eq: $name } } } } }
      sort: { date: DESC }
    ) {
      nodes {
        id
        title
        uri
        dateGmt
        excerpt
        author {
          node {
            uri
            name
          }
        }
        featuredImage {
          node {
            sourceUrl
            altText
          }
        }
      }
    }
  }
`;
